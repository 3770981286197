import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Stack,
  Typography,
  Container,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import workspace from "../../assets/andrew-neel-cckf4TsHAuw-unsplash.jpg";
import computer from "../../assets/carlos-muza-hpjSkU2UYSU-unsplash.jpg";
import world from "../../assets/maxim-hopman-IayKLkmz6g0-unsplash.jpg";
import ict from "../../assets/thisisengineering-w_zE6qlkQKA-unsplash.jpg";
const StyledTypography = styled(Typography)({
  fontSize: "30px",
  fontWeight: "600",
});

const AboutUsSection = () => {
  const data = {
    title:
      "SUNTECNIG Software Developments",
    description:
      "Specializes in creating innovative software solutions tailored to meet the specific needs of businesses and government entities",
  };

  const listDatas = [
    "Based in Katsina State",
    "Forward-thinking IT company dedicated to developing software",
    "Focus on quality and customer satisfaction",
  ];

  const images = [workspace, computer, world, ict];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <>
      <Container sx = {{marginBottom:"20px"}}>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={{ xs: 1, sm: 2, md: 8 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Box sx={{ borderRadius: "30px", overflow: "hidden" }}>
              <img
                src={images[currentImageIndex]}
                height="410px"
                width="420px"
                alt="Rotating image"
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: 0,
                transform: "translateY(-50%)",
              }}
            >
              <Button onClick={handlePrevious}>
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
              }}
            >
              <Button onClick={handleNext}>
                <ArrowForwardIosIcon />
              </Button>
            </Box>
          </Box>
          <Box>
            <Box sx={{ marginTop: "10px" }}>
              <StyledTypography color="secondary">{data.title}</StyledTypography>
              <Typography variant="body2" component="p">
                {data.description}
              </Typography>
              <Stack direction="column" sx={{ marginTop: "20px" }}>
                <StyledTypography variant="h6" color="secondary">
                  EXPANSION
                </StyledTypography>

                {listDatas.map((listdata, index) => (
                  <ListItem key={index} disablePadding>
                    {/* <ListItemButton> */}
                    <ArrowRightIcon />
                    <ListItemText>{listdata}</ListItemText>
                    {/* </ListItemButton> */}
                  </ListItem>
                ))}
              </Stack>
              
              <Stack direction="row" spacing={2} sx={{ marginTop: "15px" }}>
                <Link to="/about">
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ padding: "15px", borderRadius: "0px 20px 0px 20px" }}
                >
                  <Typography variant="body2" component="p" sx={{fontWeight: "600"}}>
                  Read More
                </Typography>
                </Button>
                </Link>
                {/* <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ padding: "15px", borderRadius: "0px 20px 0px 20px" }}
                >
                  <Typography variant="body2" component="p" sx={{fontWeight: "600"}}>
                  Office Walkthrough
                </Typography>
                </Button> */}
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default AboutUsSection;
