import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  useTheme,
} from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";

import ceo from "../../assets/ceoC.jpg";
import ctio from "../../assets/ctio.jpg";
import cfo from "../../assets/jgb.jpg";
import coo from "../../assets/ecC.jpg";

const data = [
  {
    id: 1,
    name: "Usman Ishaq Karofi",
    rank: "CEO",
    description: "Chief Executive Officer with over 10 years of experience.",
    image: ceo,
  },
  {
    id: 2,
    name: "Umar Faruk Musa",
    rank: "CTO",
    description: "Chief Technology Officer specializing in innovation.",
    image: ctio,
  },
  {
    id: 3,
    name: "Ma'awiya Kabir",
    rank: "CFO",
    description: "Chief Financial Officer ensuring financial stability.",
    image: cfo,
  },
  {
    id: 4,
    name: "Sir Jino",
    rank: "COO",
    description: "Chief Operating Officer overseeing daily operations.",
    image: coo,
  },
];

const flip = keyframes`
  0% { transform: rotateY(0); }
  100% { transform: rotateY(180deg); }
`;

const BackgroundSection = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 300,
  height: 400,
  perspective: "1000px",
  "&:hover .card-inner": {
    transform: "rotateY(180deg)",
  },
}));

const CardInner = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  transformStyle: "preserve-3d",
  transition: "transform 0.6s",
  transform: "rotateY(0)",
}));

const CardFace = styled(CardContent)(({ theme, isBack }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundColor: isBack ? theme.palette.primary.main : "white",
  color: isBack ? "white" : "black",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backfaceVisibility: "hidden",
  padding: theme.spacing(2),
  textAlign: "center",
  boxSizing: "border-box",
  overflow: "hidden",
}));

const CardFront = styled(CardFace)({
  zIndex: 2,
  transform: "rotateY(0)",
});

const CardBack = styled(CardFace)({
  transform: "rotateY(180deg)",
});

const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "150px",
  height: "150px",
  borderRadius: "50%",
  objectFit: "cover",
  marginBottom: theme.spacing(2),
}));

const OurTeam = () => {
  const theme = useTheme();

  return (
    <BackgroundSection>
      <Container>
        <Typography
          variant="h2"
          sx={{ color: "white", mb: 4, textAlign: "center" }}
        >
          Our Team
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: "white", mb: 8, textAlign: "center" }}
        >
          Meet our talented team who drive our company's success.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {data.map((item) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
              <StyledCard>
                <CardInner className="card-inner">
                  <CardFront>
                    <CardImage
                      component="img"
                      image={item.image}
                      alt={item.name}
                    />
                    <Typography variant="h5">{item.name}</Typography>
                    <Typography>{item.rank}</Typography>
                  </CardFront>
                  <CardBack>
                    <Typography>{item.description}</Typography>
                  </CardBack>
                </CardInner>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </BackgroundSection>
  );
};

export default OurTeam;
