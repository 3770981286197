import AboutUsSection from "../About/AboutUsSection";
import WhatWeDo from "./WhatWeDo";
import Header from "./Header";
import OurTeam from "./OurTeam";
import ProjectsPortfolio from "./ProjectsPortfolio";
import AboutUs from "../About/AboutUs";

const Home = () => {
  return (
    <>
      <Header  title="Welcome to Suntecnig"
      subtitle="We are dedicated to providing the best services in the industry. Join us and explore the world of opportunities."/>
      <AboutUsSection />
      <WhatWeDo />
      <OurTeam />
      <ProjectsPortfolio />
      {/* <AboutUs /> */}
    </>
  );
};

export default Home;
