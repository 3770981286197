import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6a11cb", // purple color
    },
    secondary: {
      main: "#2575fc", // blue grey color
    },
    otherColor: {
      main: "#FFF", // white color
    },
  },
  // typography: {
  //     fontFamily: 'Arial, sans-serif',
  //     h1: {
  //         fontSize: '2.5rem',
  //         fontWeight: 500,
  //         color: '#E59139', // Orange color
  //     },
  //     h2: {
  //         fontSize: '2rem',
  //         fontWeight: 500,
  //         color: '#E59139', // Orange color
  //     },
  //     body1: {
  //         fontSize: '0.875rem',
  //         color: 'rgba(0, 0, 0, 1)',
  //     },
  //     body2: {
  //         fontSize: '0.775rem',
  //         color: 'white',
  //     },
  // },
});

export default theme;
