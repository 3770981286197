import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Typography, Box, Drawer, Button, List, Divider, ListItem, ListItemButton, ListItemText, Collapse } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled, useTheme } from '@mui/material/styles';

const StyledMenuIcon = styled(MenuRoundedIcon)(({ theme }) => ({
  fontSize: '2rem',
  color: theme.palette.primary.main
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  }
}));

const servicesData = [
  { text: 'Custom Software Development', link: '/services/custom-software-development' },
  { text: 'E-Government Solutions', link: '/services/e-government-solutions' },
  { text: 'Security Software', link: '/services/security-software' },
  { text: 'Web & Mobile App Development', link: '/services/web-mobile-app-development' },
  { text: 'CCTV Installation', link: '/services/cctv-installation' },
  { text: 'IT Consultancy', link: '/services/it-consultancy' }
];

const DrawerList = () => {
  const [open, setOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const theme = useTheme();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleLinkClick = () => {
    setOpen(false);
  };

  const handleServicesClick = (event) => {
    event.stopPropagation();
    setServicesOpen(!servicesOpen);
  };

  const drawerItems = [
    { text: 'Home', link: '/' },
    { text: 'Services', isDropdown: true },
    { text: 'About', link: '/about' }
  ];

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <StyledMenuIcon />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            {drawerItems.map((item, index) => (
              <React.Fragment key={index}>
                {!item.isDropdown ? (
                  <Link to={item.link} style={{ textDecoration: 'none', color: theme.palette.text.primary }} onClick={handleLinkClick}>
                    <ListItem disablePadding>
                      <StyledListItemButton>
                        <ListItemText primary={<Typography variant="body1">{item.text}</Typography>} />
                      </StyledListItemButton>
                    </ListItem>
                  </Link>
                ) : (
                  <ListItem disablePadding onClick={handleServicesClick}>
                    <StyledListItemButton>
                      <ListItemText primary={<Typography variant="body1">{item.text}</Typography>} />
                      {servicesOpen ? <ExpandLess /> : <ExpandMore />}
                    </StyledListItemButton>
                  </ListItem>
                )}
                {item.isDropdown && (
                  <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {servicesData.map((service, index) => (
                        <Link key={index} to={service.link} style={{ textDecoration: 'none', color: theme.palette.text.primary }} onClick={handleLinkClick}>
                          <ListItem disablePadding>
                            <StyledListItemButton sx={{ pl: 4 }}>
                              <ListItemText primary={<Typography variant="body2">{service.text}</Typography>} />
                            </StyledListItemButton>
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </div>
  );
};

export default DrawerList;
