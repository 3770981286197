import React from "react";
import { Link } from "react-router-dom";

import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Container,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon1 from "@mui/icons-material/Build";
import Icon2 from "@mui/icons-material/Code";
import Icon3 from "@mui/icons-material/DesignServices";
import Icon4 from "@mui/icons-material/Settings";

import FooterBackgroundSection from "./WhatWeDoBackgroundSection";

const data = [
  {
    id: 1,
    icon: <Icon1 />,
    title: "Custom Software Development",
    description: " Building bespoke software to address unique business challenges.",
  },
  {
    id: 2,
    icon: <Icon2 />,
    title: "E-Government Solutions",
    description: "Developing platforms for improved government-citizen interaction, such as the Digital Secretary.",
  },
  {
    id: 3,
    icon: <Icon3 />,
    title: "Security Software",
    description: "Providing advanced security solutions like the Katsina Security Alert Software.",
  },
  {
    id: 4,
    icon: <Icon4 />,
    title: "Web & Mobile App Development",
    description: "Developing responsive web applications and user-friendly mobile apps.",
  },
  {
    id: 5,
    icon: <Icon1 />,
    title: "CCTV Installation",
    description: "Offering comprehensive CCTV installation services to enhance security for businesses and government facilities.",
  },
  {
    id: 6,
    icon: <Icon2 />,
    title: "IT Consultancy",
    description: "Offering expert advice on technology strategies and implementations",
  },
];

const StyledCard = styled(Card)(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
  color: "black",
  textAlign: "center",
  padding: theme.spacing(1),
}));

const StyledIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 50,
  marginBottom: theme.spacing(1),
}));

const WhatWeDo = () => {
  return (
    <FooterBackgroundSection>
      <Box sx={{ py: 8, width: "100%", textAlign: "center" }}>
        <Typography
          variant="h3"
          sx={{ color: "white", mb: 4, textAlign: "center" }}
        >
          What We Do
        </Typography>
        <Grid container spacing={3}>
          {data.map((item) => (
            <Grid item md={12} key={item.id}>
              <StyledCard>
                <StyledIcon>{item.icon}</StyledIcon>
                <CardContent>
                  <Typography variant="h5">{item.title}</Typography>
                  <Typography>{item.description}</Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
              <Link to="/about">
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ backgroundColor: "white", padding: "15px", borderRadius: "0px 20px 0px 20px", marginTop: "20px" }}
                >
                  <Typography variant="body2" component="p" sx={{fontWeight: "600"}}>
                  Read More
                </Typography>
                </Button>
                </Link>
      </Box>
    </FooterBackgroundSection>
  );
};

export default WhatWeDo;
