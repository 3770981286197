import React from "react";
import { Container } from "@mui/material";
import "./WhatWeDoBackgroundSection.css"; // Import the CSS file for styling

const FooterBackgroundSection = ({ children }) => {
  return (
    <div className="background-section">
      <div className="overlay-box">
        <Container>{children}</Container>
      </div>
    </div>
  );
};

export default FooterBackgroundSection;
