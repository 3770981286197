import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from "@mui/material";
// import emailjs from 'emailjs-com';
import CloseIcon from '@mui/icons-material/Close';

const ContactModal = (props) => {
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const form = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    //EmailJs service ID, template ID, and Public key
    const serviceId = "service_b1xiwa8";
    const templateId = "template_vo1nwk9";
    const publicKey = "jnZHmSvd9UY0l73Oi";

    //an object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Suntecnig",
      subject: subject,
      message: message,
    }

    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((result) => {
          console.log(result.text);
          alert("Message sent successfully!");
      }, (error) => {
          console.log(error.text);
          alert("Failed to send the message.");
      });

    // e.target.reset();
    handleClose();
  };

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        {props.title}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Contact Us
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            ref={form}
            onSubmit={sendEmail}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            <TextField label="Name" name="name" fullWidth required value = {name} onChange = {(e) => setName(e.target.value)} />
            <TextField label="Email" name="email" type="email" fullWidth required value = {email} onChange = {(e) => setEmail(e.target.value)} />
            <TextField label="Subject" name="subject" fullWidth required value = {subject} onChange = {(e) => setSubject(e.target.value)} />
            <TextField
              label="Message"
              name="message"
              multiline
              rows={4}
              fullWidth
              required
              value = {message}
              onChange = {(e) => setMessage(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" onClick={sendEmail} variant="contained" color="primary">
            Send Message
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactModal;
