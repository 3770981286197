import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppBar, Toolbar, Menu, MenuItem, Button, Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/system";
import logo from "../../assets/bluelogo.png";
import DrawerList from "./DrawerList";

const CustomAppBar = styled(AppBar)({
  backgroundColor: "white",
  boxShadow: "none",
  padding: "0.8rem",
});

const CustomButton = styled(Button)({
  color: "black",
  fontSize: "1rem",
  fontWeight: "600",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const CustomMenu = styled(Menu)({
  "& .MuiPaper-root": {
    backgroundColor: "white",
    boxShadow: "none",
    border: "1px solid #e0e0e0",
  },
});

const BoxMenus = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const DrawerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const AppbarComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const onHandleRedirect = () =>{
        history.go(-1);
    }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleServiceSelect = (route) => {
    history.push(route);
    handleMenuClose();
  };

  return (
    <CustomAppBar position="fixed">
      <Toolbar sx={{ gap: "2rem" }}>
        <Box
         onClick={onHandleRedirect}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            borderRadius: "1rem",
          }}
        >
          <img
            src={logo}
            height="45"
            width="85"
            alt="logo-display"
            // className="borderRadius"
          />
        </Box>
        <BoxMenus>
          <Box sx={{ flexGrow: 0.1 }} />
          <Link to="/">
            <CustomButton>Home</CustomButton>
          </Link>
          <CustomButton
            aria-controls="services-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            endIcon={<ArrowDropDownIcon />}
          >
            Services
          </CustomButton>
          <CustomMenu
            id="services-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleServiceSelect('/services/custom-software-development')}>
              Custom Software Development
            </MenuItem>
            <MenuItem onClick={() => handleServiceSelect('/services/e-government-solutions')}>
              E-Government Solutions
            </MenuItem>
            <MenuItem onClick={() => handleServiceSelect('/services/security-software')}>
              Security Software
            </MenuItem>
            <MenuItem onClick={() => handleServiceSelect('/services/web-mobile-app-development')}>
              Web & Mobile App Development
            </MenuItem>
            <MenuItem onClick={() => handleServiceSelect('/services/cctv-installation')}>
              CCTV Installation
            </MenuItem>
            <MenuItem onClick={() => handleServiceSelect('/services/it-consultancy')}>
              IT Consultancy
            </MenuItem>
          </CustomMenu>
          <Link to="/about">
            <CustomButton>About Us</CustomButton>
          </Link>
        </BoxMenus>
        <Box sx={{ flexGrow: 1 }} />
        <DrawerBox>
          <DrawerList />
        </DrawerBox>
      </Toolbar>
    </CustomAppBar>
  );
};

export default AppbarComponent;
