import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import Home from "./components/Home/Home";
import AppbarComponent from "./components/UI/AppbarComponent";
// adding animation on scroll
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "./components/UI/Footer";
import AboutUs from "./components/About/AboutUs";
import CustomSoftwareDevelopment from './components/services/CustomSoftwareDevelopment';
import EGovernmentSolutions from './components/services/EGovernmentSolutions';
import SecuritySoftware from './components/services/SecuritySoftware';
import WebMobileAppDevelopment from './components/services/WebMobileAppDevelopment';
import CCTVInstallation from './components/services/CCTVInstallation';
import ITConsultancy from './components/services/ITConsultancy';
import NotFound from './NotFound';

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="App">
      <Router>
        <AppbarComponent />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={AboutUs} />
           <Route path="/services/custom-software-development" component={CustomSoftwareDevelopment} />
        <Route path="/services/e-government-solutions" component={EGovernmentSolutions} />
        <Route path="/services/security-software" component={SecuritySoftware} />
        <Route path="/services/web-mobile-app-development" component={WebMobileAppDevelopment} />
        <Route path="/services/cctv-installation" component={CCTVInstallation} />
        <Route path="/services/it-consultancy" component={ITConsultancy} />
        <Route path="*" component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
