const servicesData = [
  {
    id: 1,
    title: 'Custom Software Development',
    subtitle: 'Unlock the full potential of your business with bespoke software solutions tailored to your unique challenges. Our custom software development services ensure that your applications are perfectly aligned with your specific processes, providing flexibility, scalability, and enhanced efficiency. Transform your operations and gain a competitive edge with software designed just for you',
    // image: 'path/to/custom-software-development-image.jpg', // Replace with actual image path
    // IconComponent: CustomIcon // Replace with actual icon component if you have one
  },
  {
    id: 2,
    title: 'E-Government Solutions',
    subtitle: 'Enhance government-citizen interaction with our innovative e-government platforms. Our solutions, like the Digital Secretary, streamline processes, improve accessibility, and foster greater engagement. Empower your community with efficient, transparent, and user-friendly digital services.',
    // image: 'path/to/e-government-solutions-image.jpg', // Replace with actual image path
    // IconComponent: GovernmentIcon // Replace with actual icon component if you have one
  },
  {
    id: 3,
    title: 'Security Software',
    subtitle: 'Ensure robust protection with our advanced security solutions, such as the Katsina Security Alert Software. Safeguard your assets and data with cutting-edge technology designed to detect, prevent, and respond to threats effectively',
    // image: 'path/to/security-software-image.jpg', // Replace with actual image path
    // IconComponent: SecurityIcon // Replace with actual icon component if you have one
  },
  {
    id: 4,
    title: 'Web & Mobile App Development',
    subtitle: 'Create seamless digital experiences with our responsive web applications and user-friendly mobile apps. Our development services ensure intuitive interfaces, optimal performance, and cross-platform compatibility to meet your business needs',
    // image: 'path/to/web-mobile-app-development-image.jpg', // Replace with actual image path
    // IconComponent: AppIcon // Replace with actual icon component if you have one
  },
  {
    id: 5,
    title: 'CCTV Installation',
    subtitle: 'Enhance your security with our comprehensive CCTV installation services. We provide tailored solutions for businesses and government facilities, ensuring continuous monitoring and peace of mind.',
    // image: 'path/to/cctv-installation-image.jpg', // Replace with actual image path
    // IconComponent: CctvIcon // Replace with actual icon component if you have one
  },
  {
    id: 6,
    title: 'IT Consultancy',
    subtitle: 'Leverage our expertise to optimize your technology strategies and implementations. We provide insightful advice tailored to your business needs, ensuring efficient and effective IT solutions.',
    // image: 'path/to/it-consultancy-image.jpg', // Replace with actual image path
    // IconComponent: ConsultancyIcon // Replace with actual icon component if you have one
  }
];

export default servicesData;
