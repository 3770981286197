import { Link, useHistory } from "react-router-dom";
import { Box, Container, Typography, Button, Grid } from "@mui/material";


const NotFound = () => {
    return ( 
        <Box>
          <Typography variant="h2" component="h1" gutterBottom>
            Sorry
            </Typography>
            
          <Typography variant="h4" component="p" gutterBottom>
            That page cannot be found
            </Typography>
            <Link to="/">Back to homepage...</Link>
        </Box>
     );
}
 
export default NotFound;