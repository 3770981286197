import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import security from "../../assets/umberto-jXd2FSvcRr8-unsplash.jpg";
import secretary from "../../assets/firmbee-com-SpVHcbuKi6E-unsplash.jpg";
import assets from "../../assets/carlos-muza-hpjSkU2UYSU-unsplash.jpg";

const data = [
  {
    id: 1,
    title: "SecureNig Alert Software",
    description: "A security alert system for real-time incident reporting and response",
    image: security,
  },
  {
    id: 2,
    title: "Digital Secretary",
    description: "An e-government platform for facilitating collaboration between government and citizens, project tracking, and issue reporting",
    image: secretary,
  },
  {
    id: 3,
    title: "Asset Management Software",
    description: "A comprehensive asset management system Software",
    image: assets,
  },
];

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  textAlign: "center",
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: theme.shadows[5],
  },
  fontFamily: "'Roboto', sans-serif",
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  fontFamily: "'Roboto', sans-serif",
}));

const ProjectsPortfolio = () => {
  return (
    <Container>
      <Typography
        variant="h2"
        sx={{
          color: "black",
          mb: 4,
          textAlign: "center",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        Our Projects
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "gray",
          mb: 8,
          textAlign: "center",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        Take a look at some of the amazing projects we have delivered.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <StyledCard>
              <CardMedia
                component="img"
                height="200"
                image={item.image}
                alt={item.title}
              />
              <StyledCardContent>
                <Typography variant="h5">{item.title}</Typography>
                <Typography>{item.description}</Typography>
              </StyledCardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProjectsPortfolio;
