import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const FooterContainer = styled(Box)({
  backgroundColor: "#282c34",
  color: "white",
  padding: "40px 20px",
  textAlign: "center",
});

const FooterTitle = styled(Typography)({
  marginBottom: "20px",
  fontWeight: "bold",
});

const footerData = {
  locations: [
    {
      city: "Katsina",
      address:
        "Katsina State",
    },
  ],
  contact: {
    company: "www.suntecnig.com",
    phone: "+234 906 3525 145",
    email: "info@suntecnig.com",
  },
  socialLinks: {
    email: "info@suntecnig.com",
    facebook: "SUNTECNIG Software Developments",
    instagram: "@suntecnig_hq",
    twitter: "@suntecnighq",
    linkedin: "SUNTECNIG Software Developments",
  },
};

const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <FooterTitle variant="h6">Our Location</FooterTitle>
            {footerData.locations.map((location, index) => (
              <Box key={index} mb={2}>
                <Typography>{location.city}</Typography>
                <Typography>{location.address}</Typography>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={4}>
            <FooterTitle variant="h6">Contact</FooterTitle>
            <Typography>{footerData.contact.company}</Typography>
            <br />
            <Typography>Nigeria: {footerData.contact.phone}</Typography>
            <Typography>{footerData.contact.email}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FooterTitle variant="h6">Social Links</FooterTitle>
            <Stack direction="row" alignItems="center" mb={1}>
              <IconButton color="inherit" aria-label="Email">
                <EmailIcon />
              </IconButton>
              <Typography>Email: {footerData.socialLinks.email}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" mb={1}>
              <IconButton color="inherit" aria-label="Facebook">
                <FacebookIcon />
              </IconButton>
              <Typography>
                Facebook: {footerData.socialLinks.facebook}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" mb={1}>
              <IconButton color="inherit" aria-label="Instagram">
                <InstagramIcon />
              </IconButton>
              <Typography>
                Instagram: {footerData.socialLinks.instagram}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" mb={1}>
              <IconButton color="inherit" aria-label="Twitter">
                <TwitterIcon />
              </IconButton>
              <Typography>Twitter: {footerData.socialLinks.twitter}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" mb={1}>
              <IconButton color="inherit" aria-label="LinkedIn">
                <LinkedInIcon />
              </IconButton>
              <Typography>
                LinkedIn: {footerData.socialLinks.linkedin}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
