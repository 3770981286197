import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import BusinessIcon from "@mui/icons-material/Business";
import VisibilityIcon from "@mui/icons-material/Visibility";
import headerImage from "../../assets/girlSmile.png"; // Replace with your image path

import desImg from "../../assets/EdittedSuntechLogo.png"; // Replace with your image path

import ceo from "../../assets/ceoC.jpg";
import ecC from "../../assets/ecC.jpg";
import Header from "../Home/Header";



const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Roboto', sans-serif",
  color: "white",
  marginBottom: theme.spacing(4),
}));

const contentData = [
  {
    title: "Description",
    description: "SUNTECNIG Software Developments, based in Katsina State, Nigeria, is a forward-thinking IT company dedicated to developing software that enhances operational efficiency and security. With a focus on quality and customer satisfaction, SUNTECNIG leverages the latest technologies to create solutions that address the evolving needs of our clients.",
        image: desImg,
  },
  {
    title: "Message from the CEO",
    description: "Dear Valued Clients and Partners, Welcome to SUNTECNIG Software Developments. As the CEO, I am proud to lead a team of dedicated professionals committed to delivering innovative technology solutions that meet the evolving needs of our clients. Our mission is to provide top-notch software and IT services that enhance operational efficiency and security for businesses and government entities alike. We believe in the power of technology to transform and drive progress, and we are dedicated to ensuring that our clients receive the best possible solutions and support. Thank you for your trust in SUNTECNIG Software Developments. We look forward to a continued partnership and to achieving new heights together.",
    rank: "Usman Ishaq Karofi (Cheif Executive Officer)",
    image: ceo,
  },
  {
    title: "Message from the Executive Chairman",
    description: "It gives me great pleasure to welcome you to SUNTECNIG Software Developments. As the Executive Chairman, I have witnessed our company's remarkable growth and the impact of our innovative solutions on businesses and government operations. Our vision is to be a leading provider of technology solutions, not just in Nigeria but globally, and we are well on our way to achieving this goal. Our success is built on the foundation of strong values, exceptional talent, and a relentless commitment to excellence. We are dedicated to fostering digital transformation and driving progress through our diverse range of services, from custom software development to CCTV installation and IT consultancy. Thank you for your continued support and confidence in our abilities. Together, we will ",
        rank: "Sadiq Sale Jino (Executive Chairman)",
image: ecC,
  },

];

const missionVisionData = [
  {
    icon: <BusinessIcon />,
    title: "Our Mission",
    description: "To deliver cutting-edge technology solutions that empower businesses and government bodies to operate more efficiently and securely.",
  },
  {
    icon: <VisibilityIcon />,
    title: "Our Vision",
    description: "To be a leading provider of innovative software solutions in Nigeria and beyond, fostering digital transformation and driving progress.",
  },
];

const AboutUs = () => {
  return (
    <div>
  <Header title = "About-Us"/>

      <Container sx={{ py: 8 }}>
        <StyledTypography variant="h2" sx={{ textAlign: "center", mb: 4 }}>
          What We Do
        </StyledTypography>
        <Box sx={{ mb: 8 }}>
          {contentData.map((item, index) => (
            <Grid
              container
              spacing={4}
              alignItems="center"
              sx={{ mb: 8 }}
              key={index}
            >
              {index % 2 === 0 ? (
                <>
                  <Grid item xs={12} md={6}>
                    <CardMedia
                      component="img"
                      height="400"
                      image={item.image}
                      alt={item.title}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h4">{item.title}</Typography>
                    <Typography variant="body1">{item.description}</Typography>
                    <Typography variant="h6">{item.rank}</Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                    <Typography variant="h4">{item.title}</Typography>
                    <Typography variant="body1">{item.description}</Typography>
                    <Typography variant="h6">{item.rank}</Typography>

                    </Grid>
                  <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                    <CardMedia
                      component="img"
                      height="400"
                      image={item.image}
                      alt={item.title}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </Box>
      </Container>

      <Box sx={{ py: 8, backgroundColor: "gray" }}>
        <Container>
          <StyledTypography variant="h2" sx={{ textAlign: "center", mb: 4 }}>
            Mission & Vision
          </StyledTypography>
          <Grid container spacing={4}>
            {missionVisionData.map((item, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    p: 4,
                    transition: "transform 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: 3,
                    },
                  }}
                >
                  <Box sx={{ fontSize: 60, mb: 2 }}>{item.icon}</Box>
                  <Typography variant="h5">{item.title}</Typography>
                  <Typography variant="body1">{item.description}</Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default AboutUs;
