import React from "react";
import { Box, Container, Typography, Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import headerImage from "../../assets/pointingGirl.png";
import ContactModal from '../contact/ContactModal';

const HeaderContainer = styled(Box)(({ theme }) => ({
  background: "linear-gradient(to right, #6a11cb, #2575fc)",
  color: "#fff",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  padding: "0 20px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
}));

const HeaderContent = styled(Container)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center",
  },
}));

const HeaderText = styled(Box)(({ theme }) => ({
  maxWidth: "600px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "20px",
  },
}));

const HeaderImage = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  height: "auto",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const HeaderButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  padding: "10px 20px",
  fontSize: "18px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Header = ({ title, subtitle, buttonText }) => {
  return (
    <HeaderContainer>
      <HeaderContent>
        <HeaderText>
          <Typography variant="h2" component="h1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h5" component="p" gutterBottom>
            {subtitle}
          </Typography>
          <ContactModal title="Let's Get In Touch" />
        </HeaderText>
        <HeaderImage src={headerImage} alt="Header Image" />
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
