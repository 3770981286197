import React from 'react';
import servicesData from './servicesData';
import Header from "../Home/Header";


const CCTVInstallation = () => {
  const service = servicesData.find(s => s.title === 'CCTV Installation');
  return <Header {...service} />;
};

export default CCTVInstallation;
