import React from 'react';
import servicesData from './servicesData';
import Header from "../Home/Header";

const WebMobileAppDevelopment = () => {
  const service = servicesData.find(s => s.title === 'Web & Mobile App Development');
  return <Header {...service} />;
};

export default WebMobileAppDevelopment;
